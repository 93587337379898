import React, { useState } from "react";
import axios from "axios";
import {
	Box,
	Button,
	TextField,
	Typography,
	Paper,
	CircularProgress,
} from "@mui/material";

interface ChatBoxProps {
	selectedDirectory: string;
}

const ChatBox: React.FC<ChatBoxProps> = ({ selectedDirectory }) => {
	const [query, setQuery] = useState("");
	const [response, setResponse] = useState("");
	const [loading, setLoading] = useState(false);
	const [sources, setSources] = useState<string[]>([]);

	const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	};

	const handleQuerySubmit = async () => {
		if (!selectedDirectory) {
			console.error("No directory selected");
			return;
		}

		setLoading(true);

		try {
			const res = await axios.post(
				"https://jt47vh32r7.execute-api.us-east-1.amazonaws.com/dev/ask-question",
				{
					query,
					directory: `${selectedDirectory}/embedding.pkl`, // Append the suffix here
				}
			);
			const data = res.data;
			setResponse(data.answer);
			setSources(data.sources);
		} catch (error) {
			console.error("Error fetching response:", error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Paper elevation={3} sx={{ p: 2, width: "100%" }}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				gap={2}
				width="100%"
			>
				<TextField
					label="Ask your question here..."
					variant="outlined"
					fullWidth
					multiline
					rows={4}
					value={query}
					onChange={handleQueryChange}
				/>
				<Button
					variant="contained"
					color="primary"
					onClick={handleQuerySubmit}
					disabled={loading}
				>
					{loading ? <CircularProgress size={24} /> : "Submit"}
				</Button>
				{response && (
					<Box mt={2} width="100%">
						<Typography variant="body1" sx={{ whiteSpace: "pre-wrap" }}>
							{response}
						</Typography>
						{/* <Typography variant="h6" sx={{ mt: 2 }}>
							Sources:
						</Typography>
						<ul>
							{sources.map((source, index) => (
								<li key={index}>{source}</li>
							))}
						</ul> */}
					</Box>
				)}
			</Box>
		</Paper>
	);
};

export default ChatBox;
