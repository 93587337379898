import React, { useState, useEffect } from "react";
import {
	Box,
	CssBaseline,
	Container,
	Grid,
	Autocomplete,
	TextField,
} from "@mui/material";
import axios from "axios";
import ChatBox from "./components/ChatBox";

const App: React.FC = () => {
	const [directories, setDirectories] = useState<
		{ display: string; original: string }[]
	>([]);
	const [selectedDirectory, setSelectedDirectory] = useState<{
		display: string;
		original: string;
	} | null>(null);

	useEffect(() => {
		// Fetch the list of directories from the API endpoint
		const fetchDirectories = async () => {
			try {
				const res = await axios.get<string[]>(
					"https://jt47vh32r7.execute-api.us-east-1.amazonaws.com/dev/list-embeddings"
				);
				const directories = res.data.map((dir) => ({
					display: dir.replace("/embedding.pkl", "").replace(/_/g, " "),
					original: dir.replace("/embedding.pkl", ""),
				}));
				setDirectories(
					directories.sort((a, b) => a.display.localeCompare(b.display))
				); // Sort the directories alphabetically
			} catch (error) {
				console.error("Error fetching directories:", error);
			}
		};

		fetchDirectories();
	}, []);

	const handleDirectoryChange = (
		event: React.SyntheticEvent<Element, Event>,
		value: { display: string; original: string } | null
	) => {
		setSelectedDirectory(value);
	};

	return (
		<Box sx={{ display: "flex", height: "100vh" }}>
			<CssBaseline />
			<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						{" "}
						{/* Adjusted width */}
						<Autocomplete
							options={directories}
							getOptionLabel={(option) => option.display}
							value={selectedDirectory}
							onChange={handleDirectoryChange}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Select Guidelines"
									variant="outlined"
									fullWidth
									sx={{ width: "100%" }} // Set width to 100%
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						{" "}
						{/* Adjusted width */}
						<ChatBox selectedDirectory={selectedDirectory?.original || ""} />
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default App;
